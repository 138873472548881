
import { useEffect, useState } from 'react'
import { useAppSelector } from '../../redux/hook'
import { getGroups, createGroup, getGrpId, generateShortLink } from '../../redux/actions'
import { useAppDispatch } from '../../redux/tpye_hooks'
import { resetGroups } from '../../redux/reducer/Groups/groupsReducer'
import useUtils from '../Utils/useUtils'

const useGroups = (): any => {
  const { notifyPromise } = useUtils()
  const [limit, setLimit] = useState(11)
  const [page, setPage] = useState(0)
  const [mount, setMount] = useState(false)
  const { data, loading } = useAppSelector((state: { groupsReducer: any }) => state.groupsReducer)
  const { data: shotLink, loading: shortLoading, success: successShort } = useAppSelector((state: { generateLinkReducer: any }) => state.generateLinkReducer)
  const { data: users, loading: lding } = useAppSelector((state: { groupReducer: any }) => state.groupReducer)
  const { success, loading: load } = useAppSelector((state: { createGrpsReducer: any }) => state.createGrpsReducer)
  const dispatch = useAppDispatch()
  const [payLoad, setPayLoad] = useState({
    grade: '',
    group: '',
    name: ''
  })

  const [open, setOpen] = useState(false)
  const handleOpen = (): void => { setOpen(true) }
  const handleClose = (): void => { setOpen(false) }

  const generateLink = (): void => {
    void notifyPromise(dispatch(generateShortLink()), { loading: 'Loading...', success: 'Se copió link al portapapeles', error: 'No se ha podido generar el link' })
  }

  const dataGroups = async (): Promise<void> => {
    await dispatch(getGroups(limit, page))
  }

  const createGrp = async (): Promise<void> => {
    await dispatch(createGroup(payLoad, notifyPromise))
  }

  const handleChange = (e: any): void => {
    setPayLoad({
      ...payLoad,
      [e.target.name]: e.target.value
    })
  }

  useEffect(() => {
    if (success || mount) void dataGroups().then(() => { })
    return () => {
      resetGroups()
    }
  }, [load, mount, success, page, limit])

  return {
    status,
    data,
    success,
    loading,
    createGrp,
    load,
    handleChange,
    getGrpId,
    users,
    payLoad,
    lding,
    setMount,
    handleOpen,
    handleClose,
    open,
    setLimit,
    setPage,
    limit,
    page,
    generateLink,
    shotLink,
    shortLoading,
    successShort
  }
}

export default useGroups
