import { Grid, Typography } from '@mui/material'
import { type FormikValues } from 'formik'
import { FormField } from '../styles/styles'
import { InputC } from '../../../components'

const LimitationForm = ({ formik }: FormikValues): JSX.Element | any => {
  const students = formik.values.students || []
  return (
    Array.from({ length: Number(formik.values.numberStudents) || 0 }).map((_, index) => (
      <>
      <Typography variant="h5" component="h4" mb={5}>
        { formik.values.numberStudents > 1
          ? `Limitaciones físicas ó psicológicas del estudiante ${index + 1}`
          : 'Limitaciones físicas ó psicológicas del estudiante'
        }
      </Typography>
        <FormField>
          <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <InputC
              label="¿Tiene alguna limitación física?"
              name={`students[${index}].hasLimitation.physical`}
              type="checkbox"
              value={students[index]?.hasLimitation?.physical || false}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={formik.touched.students?.[index]?.hasLimitation?.physical && formik.errors.students?.[index]?.hasLimitation?.physical}
            />
              <InputC
                label="¿Tiene alguna limitación pscicológica?"
                name={`students[${index}].hasLimitation.mental`}
                type="checkbox"
                value={students[index]?.hasLimitation?.mental || false}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.touched.students?.[index]?.hasLimitation?.mental && formik.errors.students?.[index]?.hasLimitation?.mental}
            />
              <InputC
                label="Descripción"
                name={`students[${index}].hasLimitation.description`}
                type="text"
                value={students[index]?.hasLimitation?.description || ''}
                onChange={formik.handleChange}
                placeholder='Describa la limitación psicológica'
                onBlur={formik.handleBlur}
                helperText={formik.touched.students?.[index]?.hasLimitation?.description && formik.errors.students?.[index]?.hasLimitation?.description}
              />
            </Grid>
          <Grid item xs={12} sm={4}>
              <InputC
                label="¿Tiene alguna enfermedad crónica?"
                name={`students[${index}].hasLimitation.chronic`}
                type="checkbox"
                value={students[index]?.hasLimitation?.chronic || false}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.touched.students?.[index]?.hasLimitation?.chronic && formik.errors.students?.[index]?.hasLimitation?.chronic}
            />
              <InputC
                label="¿Cuál?"
                name={`students[${index}].hasLimitation.which`}
                type="text"
                value={students[index]?.hasLimitation?.which || ''}
                onChange={formik.handleChange}
                placeholder='Ejemplo: Diabetes, Asma, silla de ruedas, etc...'
                onBlur={formik.handleBlur}
                helperText={formik.touched.students?.[index]?.hasLimitation?.which && formik.errors.students?.[index]?.hasLimitation?.which}
            />
              <InputC
                label="¿Está recibiendo tratamiento?"
                name={`students[${index}].hasLimitation.treatment`}
                type="checkbox"
                value={students[index]?.hasLimitation?.treatment || false}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.touched.students?.[index]?.hasLimitation?.treatment && formik.errors.students?.[index]?.hasLimitation?.treatment}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputC
              label="¿Está tomando medicación controlada?"
              name={`students[${index}].hasLimitation.controlledMedication`}
              type="checkbox"
              value={students[index]?.hasLimitation?.controlledMedication || false}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={formik.touched.students?.[index]?.hasLimitation?.controlledMedication && formik.errors.students?.[index]?.hasLimitation?.controlledMedication}
            />
            <InputC
              label="¿Quién suministra la medicación?"
              name={`students[${index}].hasLimitation.whoSuppliesMedication`}
              type="text"
              value={students[index]?.hasLimitation?.whoSuppliesMedication || ''}
              onChange={formik.handleChange}
              placeholder='Ejemplo: Doctor, Familiar'
              onBlur={formik.handleBlur}
              helperText={formik.touched.students?.[index]?.hasLimitation?.whoSuppliesMedication && formik.errors.students?.[index]?.hasLimitation?.whoSuppliesMedication}
            />
            <InputC
                label="Descripción"
                name={`students[${index}].hasLimitation.description`}
                type="text"
                value={students[index]?.hasLimitation?.description || ''}
                onChange={formik.handleChange}
                placeholder='Describa la limitación física'
                onBlur={formik.handleBlur}
                helperText={formik.touched.students?.[index]?.hasLimitation?.description && formik.errors.students?.[index]?.hasLimitation?.description}
              />
            </Grid>
            {formik.values.hasLimitation.treatment && (
              <Grid item xs={12}>
                <InputC
                  label="Descripción del tratamiento"
                  name={`students[${index}].hasLimitation.treatmentDescription`}
                  type="text"
                  value={students[index]?.hasLimitation?.treatmentDescription || ''}
                  onChange={formik.handleChange}
                  placeholder='Describa el tratamiento'
                  onBlur={formik.handleBlur}
                  helperText={formik.touched.students?.[index]?.hasLimitation?.treatmentDescription && formik.errors.students?.[index]?.hasLimitation?.treatmentDescription}
                />
              </Grid>
            )}
            {formik.values.hasLimitation.controlledMedication && (
              <Grid item xs={12}>
                <InputC
                  label="Descripción de la medicación"
                  name="hasLimitation.medicationDescription"
                  type="text"
                  value={formik.values.hasLimitation.medicationDescription || ''}
                  onChange={formik.handleChange}
                  placeholder='Describa la medicación'
                  onBlur={formik.handleBlur}
                  helperText={formik.touched.hasLimitation?.medicationDescription && formik.errors.hasLimitation?.medicationDescription}
                />
              </Grid>
            )}
            {students[index]?.hasLimitation?.controlledMedication && (
              <Grid item xs={12}>
                <InputC
                  label="Descripción de la medicación"
                  name={`students[${index}].hasLimitation.medicationDescription`}
                  type="text"
                  value={students[index]?.hasLimitation?.medicationDescription || ''}
                  onChange={formik.handleChange}
                  placeholder='Describa la medicación'
                  onBlur={formik.handleBlur}
                  helperText={formik.touched.students?.[index]?.hasLimitation?.medicationDescription && formik.errors.students?.[index]?.hasLimitation?.medicationDescription}
                />
              </Grid>
            )}
          </Grid>
      </FormField>
      </>
    ))
  )
}

export default LimitationForm
