import { type SetStateAction, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { type GridRowSelectionModel, type GridColDef, GridToolbar } from '@mui/x-data-grid'
import TextField from '@mui/material/TextField'
import { Box, InputLabel, MenuItem, Select, Tooltip, Typography, Grid } from '@mui/material'
import { useGroups } from '../../hooks'
import { Button, DataGrid, ViewStack, Modal, Form } from '../../components'
import toast, { Toaster } from 'react-hot-toast'
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded'

const Groups = (): JSX.Element => {
  const {
    data,
    loading,
    load,
    createGrp,
    handleChange,
    payLoad,
    setMount,
    handleOpen,
    handleClose,
    open,
    setLimit,
    setPage,
    limit,
    page,
    generateLink,
    shotLink,
    successShort
  } = useGroups()

  const [paginationModel, setPaginationModel] = useState({
    page,
    pageSize: limit
  })
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([])

  const columns: GridColDef[] = [
    { field: 'grade', headerName: 'Grado', width: 90 },
    { field: 'group', headerName: 'Grupo', width: 150 },
    { field: 'name', headerName: 'Name', width: 150 },
    {
      field: 'mainTeacher_id',
      headerName: 'Maestro Principal',
      width: 200,
      renderCell: (params) => {
        return (
          <Typography>
            {params.row.mainTeacher_id !== null ? params.row.mainTeacher_id.name : 'No asignado'}
          </Typography>
        )
      }
    },
    {
      field: 'students',
      headerName: 'Total de estudiantes',
      width: 200,
      renderCell: (params) => {
        const students = params.row.students_id || []
        return (
          <div>
            {students.length} / {params.row.studentsLimit}
          </div>
        )
      }
    },
    {
      field: 'icons',
      headerName: '',
      width: 200,
      renderCell: (params) => {
        return (
          <ViewStack sx={{ top: '0', height: '100%!important' }}>
            <Tooltip title="Ver grupo" placement="top">
              <Link style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}
                to={`/groups/${params.row._id}`}><RemoveRedEyeRoundedIcon /></Link>
            </Tooltip>
          </ViewStack>
        )
      }
    }
  ]

  const copyToClipboard = async (text: string): Promise<void> => {
    try {
      await navigator.clipboard.writeText(text)
      toast.success('Link copiado al portapapeles')
    } catch (err) {
      toast.error('Error al copiar el link')
    }
  }

  useEffect(() => {
    setMount(true)
    return () => {
      setMount(false)
    }
  }, [])

  useEffect(() => {
    if (successShort) {
      const newLink = `${window.location.origin}/${shotLink.shortUrl}`
      void copyToClipboard(newLink)
    }
  }, [successShort])

  return (
    <ViewStack sx={{ padding: '0 12px' }} alignItems='flex-start'>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <Modal open={open} handleClose={handleClose} >
        <Form>
          <h1>Grupo</h1>
          <div style={{ marginTop: '20px', width: '100%' }}>
            <InputLabel id="grade_label">Selecciona una opción</InputLabel>
            <Select id="grade_label" fullWidth variant="outlined" onChange={handleChange} name='grade' value={payLoad.grade}>
              {(function () {
                const grades = []
                for (let i = 1; i <= 6; i++) {
                  grades.push(<MenuItem key={i} value={i}>{i}</MenuItem>)
                }
                return grades
              }())}
            </Select>
          </div>
          <div style={{ marginTop: '20px', width: '100%' }}>
            <TextField fullWidth id="outlined-basic" label="Grupo" variant="outlined" onChange={handleChange} name='group' />
          </div>
          <div style={{ marginTop: '20px', width: '100%' }}>
            <InputLabel id="option_label">Selecciona una opción</InputLabel>
            <Select id="option_label" fullWidth variant="outlined" onChange={handleChange} name='name' value={payLoad.name}>
              <MenuItem value='KINDER'>Kinder</MenuItem>
              <MenuItem value='PRIMARIA'>Primaria</MenuItem>
            </Select>
          </div>
          <Button onClick={createGrp} variant="solid" color="#000" backgroundColor="rgba(231, 203, 67, 1)" >
            {load ? 'Creando...' : 'Crear'}
          </Button>
        </Form>
      </Modal>
      <div style={{ marginTop: '20px', width: '100%' }}>
        <Box sx={{ top: '0', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          <h1>Grupos</h1>
          <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
            <Grid item xs={12} sm={4}>
              <Button onClick={handleOpen} variant="solid" width='100%' >
            Crear grupo
          </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button onClick={generateLink} variant="solid" width='100%' >
                Link de registro
              </Button>
            </Grid>
          </Grid>
        </Box>
        <DataGrid
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true
            }
          }}
          rows={data}
          columns={columns}
          getRowId={(row: any) => row._id}
          pagination
          onPaginationModelChange={(newModel: any) => {
            console.log(newModel)
            setPaginationModel(newModel)
            setPage(newModel.page)
            setLimit(newModel.pageSize)
          }}
          onSelectionModelChange={(newSelection: SetStateAction<GridRowSelectionModel>) => {
            setRowSelectionModel(newSelection)
          }}
          selectionModel={rowSelectionModel}
          pageSize={limit}
          pageSizeOptions={[11]}
          rowCount={100}
          paginationMode="server"
          paginationModel={paginationModel}
          loading={loading}
          keepNonExistentRowsSelected
          autoHeight
        />
      </div>
    </ViewStack>
  )
}

export default Groups
