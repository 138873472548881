import { GridToolbar, type GridColDef } from '@mui/x-data-grid'
import Typography from '@mui/material/Typography'
import { Link, useParams } from 'react-router-dom'
import { DataGrid, ViewStack } from '../../components'
import { useGroup } from '../../hooks'
import { useSeparateStudentsByGroup } from './hooks/useSeparateStudentsByGroup'

const Students = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const { users, loading } = useGroup(id)

  // Hook para transformar y separar los estudiantes
  const formattedStudents = useSeparateStudentsByGroup(users?.students_id || null)

  const columns: GridColDef[] = [
    {
      field: 'lastName',
      headerName: 'Apellido',
      width: 150,
      renderCell: (params) => (
        <Typography>{params.row.lastName}</Typography>
      )
    },
    {
      field: 'name',
      headerName: 'Nombre',
      width: 150,
      renderCell: (params) => (
        <Typography>{params.row.name}</Typography>
      )
    },
    {
      field: 'email',
      headerName: 'Correo',
      width: 200,
      renderCell: (params) => (
        <Typography>{params.row.email || 'No disponible'}</Typography>
      )
    },
    {
      field: 'curp',
      headerName: 'Curp',
      width: 200,
      renderCell: (params) => (
        <Typography>{params.row.curp || 'No disponible'}</Typography>
      )
    },
    {
      field: 'phone',
      headerName: 'Teléfono',
      width: 200,
      renderCell: (params) => (
        <Typography>{params.row.phone || 'No disponible'}</Typography>
      )
    },
    {
      field: 'show',
      headerName: 'Ver',
      width: 200,
      renderCell: (params) => (
        <div>
          <Link
            style={{ textDecoration: 'none', color: 'inherit' }}
            to={`/student/${params.row._id}?group_id=${params.row.group_id || ''}`}
          >
            Ver alumno
          </Link>
        </div>
      )
    }
  ]

  return (
    <ViewStack sx={{ padding: '0 12px' }} alignItems='flex-start'>
      <div style={{ marginTop: '20px', width: '100%' }}>
        <h1>Alumnos</h1>
        {!loading && formattedStudents.length > 0 && (
          <DataGrid
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true
              }
            }}
            rows={formattedStudents}
            columns={columns}
            getRowId={(row: any) => `${row._id}-${Math.random()}`}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 11
                }
              }
            }}
            pageSizeOptions={[11]}
            disableRowSelectionOnClick
            loading={loading}
          />
        )}
      </div>
    </ViewStack>
  )
}

export default Students
