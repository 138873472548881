import { getEvents, createEvent, notificationTopic } from '../../../api/services'
import { useEffect, useState } from 'react'

const useHome = (): { events: { success: boolean, data: any[] }, createAllEvent: (payload: any) => Promise<void>, createEventsRes: { success: boolean, data: any[], error: string }, sendTopic: (payload: { topic: string, notification: { title: string, body: string } }) => Promise<any> } => {
  const [events, setEvents] = useState({
    success: false,
    data: []
  })
  const [createEventsRes, setCreateEvents] = useState({
    success: false,
    data: [],
    error: ''
  })

  const getAllEvents = async (): Promise<void> => {
    const event = await getEvents()
    try {
      setEvents({
        success: true,
        data: event.data
      })
    } catch (error) {
      setEvents({
        success: false,
        data: []
      })
    }
  }

  const sendTopic = async (payload: { topic: string, notification: { title: string, body: string } }): Promise<any> => {
    try {
      const rest = await notificationTopic(payload)
      console.log('Res', rest)
    } catch (error) {
      console.log('Error', error)
    }
  }

  const createAllEvent = async (payload: any): Promise<any> => {
    const event = await createEvent(payload)
    console.log(payload)
    const body = {
      topic: payload.group_id,
      notification: {
        title: payload.title,
        body: payload.description
      }
    }
    try {
      setCreateEvents({
        success: true,
        data: event.data,
        error: ''
      })
      void sendTopic(body)
    } catch (error) {
      console.log(error)
      setCreateEvents({
        success: false,
        data: [],
        error: error as string
      })
    }
  }

  useEffect(() => {
    void getAllEvents()
  }, [])

  return { events, createAllEvent, createEventsRes, sendTopic }
}

export default useHome
