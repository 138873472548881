import { Button, Box, Typography, Container, Paper, InputLabel, MenuItem, Select } from '@mui/material'
import logoMiramar from '../../assets/images/logo_miramar.png'
import useRegisterTeacher from './hook/useRegisterTeacher'
import { useAppSelector } from '../../redux/hook'
import { InputC } from '../../components'
import backgroundMiramar from '../../assets/images/backgroun_form.png'

const RegistroMaestro = (): JSX.Element => {
  const { formik } = useRegisterTeacher()
  const { success: successGrps, data } = useAppSelector((state) => state.grpsOnlyReducer)
  return (
    <Box bgcolor="#243d99" display="flex" flexDirection="column" sx={{ width: '100%', backgroundImage: `url(${backgroundMiramar})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
      <Box component="header" bgcolor="#243d99" color="primary.contrastText" py={4} sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        <Container sx={{ display: 'flex', alignItems: 'center', gap: 2, width: '100px' }}>
          <img src={logoMiramar} alt="Colegio Miramar" width="80" />
        </Container>
        <Container sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography variant="h4" fontWeight="bold">Registro de Docentes</Typography>
        </Container>
      </Box>
      <Container maxWidth="sm">
        <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
          <form onSubmit={formik.handleSubmit}>
            <InputC name="name" label="Nombre" fullWidth helperText={formik.errors.name} error={formik.touched.name && formik.errors.name} onChange={formik.handleChange} value={formik.values.name} />
            <InputC name="lastName" label="Apellido" fullWidth helperText={formik.errors.lastName} error={formik.touched.lastName && formik.errors.lastName} onChange={formik.handleChange} value={formik.values.lastName} />
            <InputC name="email" label="Correo electrónico" fullWidth helperText={formik.errors.email} error={formik.touched.email && formik.errors.email} onChange={formik.handleChange} value={formik.values.email} />
            <InputC name="password" label="Contraseña" type="password" fullWidth helperText={formik.errors.password} error={formik.touched.password && formik.errors.password} onChange={formik.handleChange} value={formik.values.password} />
            <InputC name="phone" label="Teléfono" fullWidth helperText={formik.errors.phone} error={formik.touched.phone && formik.errors.phone} onChange={formik.handleChange} value={formik.values.phone} />
            <InputC name="address" label="Domicilio" fullWidth helperText={formik.errors.address} error={formik.touched.address && formik.errors.address} onChange={formik.handleChange} value={formik.values.address} />
            <InputC name="birthdate" label="Fecha de Nacimiento" type='date' fullWidth helperText={formik.errors.birthdate} error={formik.touched.birthdate && formik.errors.birthdate} onChange={formik.handleChange} value={formik.values.birthdate} />
            <InputC name="curp" label="CURP" fullWidth helperText={formik.errors.curp} error={formik.touched.curp && formik.errors.curp} onChange={formik.handleChange} value={formik.values.curp} />
            <InputC name="rfc" label="RFC" fullWidth helperText={formik.errors.rfc} error={formik.touched.rfc && formik.errors.rfc} onChange={formik.handleChange} value={formik.values.rfc} />
            <InputC name="nss" label="NSS" fullWidth helperText={formik.errors.nss} error={formik.touched.nss && formik.errors.nss} onChange={formik.handleChange} value={formik.values.nss} />
            <div style={{ margin: '20px 0px', width: '100%' }}>
              {successGrps &&
                <><InputLabel id="assignedGroupId">Selecciona el grupo asignado</InputLabel><Select id="assignedGroupId" fullWidth variant="outlined" onChange={formik.handleChange} name='assignedGroupId' value={formik.values.assignedGroupId}>
                  {[...data.data]
                    .sort((a: any, b: any) => a.name - b.name)
                    .map((text: any) => (
                      <MenuItem value={text.id} key={text.id}>
                        {text.grade} {text.group} - {text.name}
                      </MenuItem>
                    ))
                  }
                </Select>
                </>
              }
            </div>

            <Box mb={4}>
              <Typography variant="body1" gutterBottom>Identificación Oficial</Typography>
              <input
                id="dni"
                name="dni"
                type="file"
                onChange={(event) => {
                  const file = event.currentTarget.files?.[0]
                  void formik.setFieldValue('dni', file)
                }}
                accept="image/*,.pdf"

              />
              {formik.touched.dni && Boolean(formik.errors.dni) && (
                <Typography variant="body2" color="error">
                  {formik.errors.dni}
                </Typography>
              )}
            </Box>

            <Box mb={4}>
              <Typography variant="body1" gutterBottom>Cédula profesional (opcional)</Typography>
              <input
                id="cedula"
                name="cedula"
                type="file"
                onChange={(event) => {
                  const file = event.currentTarget.files?.[0]
                  void formik.setFieldValue('cedula', file)
                }}
                accept="image/*,.pdf"
              />
            </Box>

            <Button type="submit" variant="contained" color="primary" fullWidth>
              Registrarse
            </Button>
          </form>
        </Paper>
      </Container>
    </Box>
  )
}

export default RegistroMaestro
