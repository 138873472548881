import { useState } from 'react'
import { Box, Grid, InputLabel, MenuItem, Typography, Select, FormControlLabel, Switch } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { useSearchParams } from 'react-router-dom'
import { Stepper, InputC, ViewStack, PaperComponent } from '../../components'
import { StyledForm, TitleStyled } from './styles/styles'
import useStudentForm from './hooks/useStudentForm'
import {
  MotherForm,
  HealthDetailsForm,
  LimitationForm,
  AditionalForm,
  EmergencyForm,
  ParentsForm
} from './Forms'
import background from '../../assets/images/background_small.png'

const StudentForm = (): JSX.Element => {
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token') as string

  const { steps, formik, loading, success, data, successGrps } = useStudentForm(token)
  const [activeStep, setActiveStep] = useState(0)

  return (
    <ViewStack viewHeight={activeStep === 7 ? 1 : null} important paddingX={4} margin={'auto'} gradient={`url(${background})`}>
      <Grid container justifyContent="center" alignItems="center" spacing={{ xs: 2, md: 3 }}>
      <TitleStyled>
        Registro de Estudiantes
      </TitleStyled>
        <Grid item md={10} xs={12}>
          <PaperComponent>
            <Stepper steps={steps} activeStep={activeStep} setActiveStep={setActiveStep} formik={formik} success={success}>
              <Box sx={{ width: '100%', m: 'auto' }}>
                <StyledForm onSubmit={formik.handleSubmit}>
                  {activeStep === 0 && (
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <FormControlLabel
                            sx={{ color: 'white' }}
                            control={<Switch
                              onChange={formik.handleChange}
                              name="multiUser"
                              sx={{ m: 1 }}
                              checked={formik.values.multiUser} />
                            }
                          label={'Registrar varios alumnos con los mismos datos'} />
                        </Grid>
                      {formik.values.multiUser && (
                        <Grid item xs={12}>
                          <InputC type="number" name="numberStudents" fullWidth placeholder="Ingresa el número de alumnos" label="Número de alumnos" variant="outlined" value={formik.values.numberStudents} onChange={formik.handleChange} onBlur={formik.handleBlur} helperText={formik.touched.numberStudents && formik.errors.numberStudents} />
                        </Grid>
                      )}
                      { formik.values.multiUser && formik.values.numberStudents >= 2
                        ? (
                            Array.from({ length: Number(formik.values.numberStudents) || 0 }).map((_, index) => (
                          <Grid item xs={12} key={index}>
                            <TitleStyled>
                              Alumno {index + 1}
                            </TitleStyled>
                            <InputC
                              type="text"
                              name={`students[${index}].name`}
                              fullWidth
                              placeholder="Ingresa tu nombre aquí"
                              label="Nombre"
                              variant="outlined"
                              value={formik.values.students?.[index]?.name || ''}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              helperText={formik.touched.students?.[index]?.name && formik.errors.students?.[index]?.name}
                            />
                            <InputC
                              type="text"
                              name={`students[${index}].lastName`}
                              fullWidth
                              placeholder="Ingresa tu apellido aquí"
                              label="Apellido"
                              variant="outlined"
                              value={formik.values.students?.[index]?.lastName || ''}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              helperText={formik.touched.students?.[index]?.lastName && formik.errors.students?.[index]?.lastName}
                            />
                            <InputC
                              type="text"
                              name={`students[${index}].curp`}
                              fullWidth
                              placeholder="Ingresa CURP aquí"
                              label="CURP"
                              variant="outlined"
                              value={formik.values.students?.[index]?.curp || ''}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              helperText={formik.touched.students?.[index]?.curp && formik.errors.students?.[index]?.curp}
                            />
                          </Grid>
                            ))
                          )
                        : (

                        <Grid item xs={12} sm={4}>
                          <InputC type="text" name="name" fullWidth placeholder="Ingresa tu nombre aquí" label="Nombre" variant="outlined" value={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur} helperText={formik.touched.name && formik.errors.name} />
                          <InputC type="text" name="lastName" fullWidth placeholder="Ingresa tu apellido aquí" label="Apellido" variant="outlined" value={formik.values.lastName} onChange={formik.handleChange} onBlur={formik.handleBlur} helperText={formik.touched.lastName && formik.errors.lastName} />
                          <InputC type="text" name="curp" fullWidth placeholder="Ingresa CURP aquí" label="CURP" variant="outlined" value={formik.values.curp} onChange={formik.handleChange} onBlur={formik.handleBlur} helperText={formik.touched.curp && formik.errors.curp} />
                        </Grid>
                          )}

                        <Grid item xs={12} sm={4}>
                          <InputC type="text" name="birthEntity.city" fullWidth placeholder="Ingresa tu ciudad aquí" label="Ciudad" variant="outlined" value={formik.values.birthEntity.city} onChange={formik.handleChange} onBlur={formik.handleBlur} helperText={formik.touched.birthEntity?.city && formik.errors.birthEntity?.city} />
                          <InputC type="text" name="birthEntity.country" fullWidth placeholder="Ingresa tu país aquí" label="Estado" variant="outlined" value={formik.values.birthEntity.country} onChange={formik.handleChange} onBlur={formik.handleBlur} helperText={formik.touched.birthEntity?.country && formik.errors.birthEntity?.country} />
                          <InputC fullWidth placeholder="Ingresa tu correo electrónico aquí" label="Correo electrónico" name="email" variant="outlined" value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} helperText={formik.touched.email && formik.errors.email} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <InputC type="text" name="phone" fullWidth placeholder="Ingresa tu teléfono aquí" label="Teléfono" variant="outlined" value={formik.values.phone} onChange={formik.handleChange} onBlur={formik.handleBlur} helperText={formik.touched.phone && formik.errors.phone} />
                          <InputC type="text" name="address" fullWidth placeholder="Ingresa tu dirección aquí" label="Dirección" variant="outlined" value={formik.values.address} onChange={formik.handleChange} onBlur={formik.handleBlur} helperText={formik.touched.address && formik.errors.address} />
                          <InputC type="password" name="password" fullWidth placeholder="Ingresa tu contraseña aquí" label="Contraseña" variant="outlined" value={formik.values.password} onChange={formik.handleChange} onBlur={formik.handleBlur} helperText={formik.touched.password && formik.errors.password} />
                        </Grid>
                        { formik.values.multiUser && formik.values.numberStudents >= 2 && (
                          Array.from({ length: Number(formik.values.numberStudents) || 0 }).map((_, index) => (
                          <Grid item xs={12} key={index}>
                            <TitleStyled>
                              Alumno {index + 1}
                            </TitleStyled>
                            <div style={{ marginTop: '20px', width: '100%' }}>
                              {successGrps &&
                              <><InputLabel id={`students[${index}].group_id`}>Selecciona una opción</InputLabel><Select id={`students[${index}].group_id`} fullWidth variant="outlined" onChange={formik.handleChange} name={`students[${index}].group_id`} value={formik.values.students?.[index]?.group_id}>
                                  {[...data.data]
                                    .sort((a: any, b: any) => a.name - b.name)
                                    .map((text: any) => (
                                    <MenuItem value={text.id} key={text.id}>
                                      {text.grade} {text.group} - {text.name}
                                    </MenuItem>
                                    ))
                                }
                                  </Select>
                                </>
                              }
                            </div>
                          </Grid>
                          ))
                        )}
                        {!formik.values.multiUser && formik.values.numberStudents === 1 &&
                        <Grid item xs={12} sm={4}>
                        <div style={{ marginTop: '20px', width: '100%' }}>
                          {successGrps &&
                          <><InputLabel id="group_id">Selecciona una opción</InputLabel><Select id="group_id" fullWidth variant="outlined" onChange={formik.handleChange} name='group_id' value={formik.values.group_id}>
                              {[...data.data]
                                .sort((a: any, b: any) => a.name - b.name)
                                .map((text: any) => (
                                <MenuItem value={text.id} key={text.id}>
                                  {text.grade} {text.group} - {text.name}
                                </MenuItem>
                                ))
                            }
                              </Select>
                            </>
                          }
                        </div>
                        </Grid>
                        }
                      </Grid>
                    </>
                  )}
                  {activeStep === 1 && <MotherForm formik={formik} />}
                  {activeStep === 2 && <ParentsForm formik={formik} />}
                  {activeStep === 3 && <HealthDetailsForm formik={formik} />}
                  {activeStep === 4 && <LimitationForm formik={formik} />}
                  {activeStep === 5 && <EmergencyForm formik={formik} />}
                  {activeStep === 6 && <AditionalForm formik={formik} />}
                  {activeStep === 7 && (
                    <div style={{ marginTop: '20px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                      <Typography variant="h6" component="h6" mb={5}>
                        Estás a punto de terminar, guarda los datos para finalizar
                      </Typography>
                      <LoadingButton loading={loading} variant="contained" color="primary"
                        type="submit">Guardar datos</LoadingButton>
                    </div>
                  )}
                </StyledForm>
              </Box>
            </Stepper>
          </PaperComponent>
        </Grid>
      </Grid>
    </ViewStack>
  )
}

export default StudentForm
