import { type AxiosResponse } from 'axios'
import { TEACHER } from '../../../redux/constants'
import { createTeacherService } from '../../../api/services/Teacher/teacher.service'

const createTeacher = (payload: any) => async (dispatch: any): Promise<any> => {
  console.log('payload', payload)
  dispatch({ type: TEACHER.TEACHER_LOADING })
  try {
    const res: AxiosResponse<any> = await createTeacherService(payload)
    dispatch({ type: TEACHER.TEACHER_SUCCESS, payload: res.data.data })
  } catch (error) {
    console.log('error', error)
    dispatch({ type: TEACHER.TEACHER_ERROR, payload: error })
  }
}

export { createTeacher }
