import { useEffect, useMemo } from 'react'
import { useAppSelector } from '../../redux/hook'
import { getGrpId } from '../../redux/actions'
import { useAppDispatch } from '../../redux/tpye_hooks'
import { resetGroups } from '../../redux/reducer/Groups/groupsReducer'

// Función para filtrar estudiantes
const filterStudents = (users: any, groupId: string): any => {
  const filteredStudents = users?.students_id?.map((student: any) => {
    if (student.group_id !== null) {
      return student
    }

    if (student.students && student.students.length > 0) {
      const filteredNestedStudents = student.students.filter((nestedStudent: any) => {
        return nestedStudent.group_id === groupId
      })

      if (filteredNestedStudents.length > 0) {
        return {
          ...student,
          students: filteredNestedStudents
        }
      }
    }

    return null
  }).filter(Boolean)

  return filteredStudents
}

// Hook personalizado useGroup
const useGroup = (id: string | undefined): any => {
  const { data: users, loading } = useAppSelector((state: { groupReducer: any }) => state.groupReducer)
  const dispatch = useAppDispatch()

  // Función para despachar la acción y obtener datos del grupo
  const dataGroups = async (): Promise<void> => {
    try {
      await dispatch(getGrpId(id))
    } catch (error) {
      console.error('Error fetching group data:', error)
    }
  }

  // Efecto para obtener los datos cuando se monta el componente
  useEffect(() => {
    if (id) {
      void dataGroups()
    }

    return () => {
      dispatch(resetGroups())
    }
  }, [id, dispatch])

  // Filtrar estudiantes con useMemo para evitar recalculados innecesarios
  const filteredStudents = useMemo(() => filterStudents(users, id as string), [users, id])

  return {
    users: { ...users, students_id: filteredStudents },
    loading
  }
}

export default useGroup
