import { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import useFirebase from '../../../../hooks/FirebseHooks/useFirebase'
import useHome from '../../../../Views/Home/hooks/useHome'
import axios, { type AxiosRequestConfig } from 'axios'

interface IUseHomeWorks {
  formik: any
  file: File[]
  errorFiles: boolean
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleDrop: (e: React.DragEvent<HTMLDivElement>) => void
  handleRemoveFile: (index: number, bucketName?: string) => Promise<void>
  uploadResume: any
  status: string
  handleSubmitFileFirebase: () => Promise<void>
  loading: boolean
  deleteFile: (bucketName: string) => Promise<{ success: boolean }>
  successUpload: boolean
}

const useHomeWorks = (): IUseHomeWorks => {
  const [file, setFile] = useState<File[]>([])
  const [errorFiles, setErrorFiles] = useState(false)
  const [loading, setLoading] = useState(false)
  const [successUpload, setSuccessUpload] = useState(false)
  const { uploadFile, uploadResume, status, deleteFile } = useFirebase()
  const { sendTopic } = useHome()

  const validationSchema = Yup.object({
    title: Yup.string().required('El título es requerido'),
    description: Yup.string().required('La descripción es requerida'),
    subject: Yup.string().required('La materia es requerida'),
    dueDate: Yup.date().required('La fecha de entrega es requerida').min(new Date(), 'La fecha de entrega no puede ser menor a la fecha actual'),
    file: Yup.array().min(1, 'Debe subir al menos un archivo'),
    closedHomework: Yup.boolean(),
    selectedParentDate: Yup.boolean()
  })

  const handleSubmitFileFirebase = async (): Promise<void> => {
    if (file.length > 0) {
      setLoading(true)
      const url = await Promise.all(file.map(async (f: File) => {
        return await uploadFile(f, `homeworks/${formik.values.title}/${f.name}`)
      }))

      try {
        console.log(url)
        void formik.setFieldValue('file', url)
        setSuccessUpload(true)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
  }

  const createHomework = async (data: any): Promise<void> => {
    const token = localStorage.getItem('dataUser')
    if (!token) return
    const config: AxiosRequestConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'http://localhost:8000/api/v1/home-works',
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(data)
    }

    try {
      const response = await axios.request(config)
      console.log(JSON.stringify(response.data))
      alert('Tarea creada correctamente')
    } catch (error) {
      console.error(error)
      alert('Error al crear la tarea')
    }
  }

  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      subject: '',
      dueDate: '',
      file: null,
      closedHomework: false,
      selectedParentDate: false,
      teacher_id: '66dfc9498c03cd80b8ec5cca',
      group_id: '66bebdcba553077f8eee7997'
    },
    validationSchema,
    onSubmit: async (values) => {
      console.log(values)
      await createHomework(values)
      void sendTopic({ topic: '6655acec51887b04587fe522', notification: { title: values.title, body: values.description } })
    }
  })

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files && event.target.files[0].size > 3145728) {
      setErrorFiles(true)
      return
    }

    if (event.target.files) {
      const filesArray = Array.from(event.target.files)
      setFile([...file, ...filesArray])
    }
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault()
    const droppedFiles = Array.from(e.dataTransfer.files)
    setFile([...file, ...droppedFiles])
  }

  const handleRemoveFile = async (index: number, bucketName?: string): Promise<void> => {
    if (!successUpload) {
      const newFiles = file.filter((_, i) => i !== index)
      setFile(newFiles)
    } else {
      if (bucketName) {
        const res: { success: boolean } = await deleteFile(bucketName)
        if (res.success) {
          const newFiles = file.filter((_, i) => i !== index)
          setFile(newFiles)
        }
      }
    }
  }

  return {
    formik,
    file,
    errorFiles,
    handleFileChange,
    handleDrop,
    handleRemoveFile,
    uploadResume,
    status,
    handleSubmitFileFirebase,
    loading,
    deleteFile,
    successUpload

  }
}

export default useHomeWorks
