import * as Yup from 'yup'
import { useFormik, type FormikProps } from 'formik'
import { useEffect } from 'react'
import { useAppDispatch } from '../../../redux/hook'
import { getOnlyGrp, createTeacher } from '../../../redux/actions'
// import { resetStateT } from '../../../redux/reducer/Users/teacherReducer'

export interface TeacherPayload {
  name: string
  lastName: string
  email: string
  password: string
  phone: string
  address: string
  assignedGroupId: string
  dni: File | null
  cedula: File | null
  birthdate: string
  curp: string
  rfc: string
  nss: string
}

const useRegisterTeacher = (): { formik: FormikProps<TeacherPayload>, initialValues: TeacherPayload, validationSchema: Yup.ObjectSchema<any> } => {
  const dispatch = useAppDispatch()

  const validationSchema: Yup.ObjectSchema<any> = Yup.object().shape({
    name: Yup.string().required('El nombre es obligatorio'),
    lastName: Yup.string().required('El apellido es obligatorio'),
    email: Yup.string().email('Correo electrónico no válido').required('El correo es obligatorio'),
    password: Yup.string().min(8, 'La contraseña debe tener al menos 8 caracteres').required('La contraseña es obligatoria'),
    phone: Yup.string().required('El teléfono es obligatorio'),
    address: Yup.string().required('El domicilio es obligatorio'),
    assignedGroupId: Yup.string().required('El grupo asignado es obligatorio'),
    birthdate: Yup.date().required('La fecha de nacimiento es obligatoria').max(new Date(), 'La fecha de nacimiento no puede ser mayor a la fecha actual'),
    curp: Yup.string().required('El CURP es obligatorio'),
    rfc: Yup.string().required('El RFC es obligatorio'),
    nss: Yup.string().required('El NSS es obligatorio'),
    dni: Yup.mixed(),
    cedula: Yup.mixed()
  })

  const initialValues: TeacherPayload = {
    name: '',
    lastName: '',
    email: '',
    password: '',
    phone: '',
    address: '',
    assignedGroupId: '',
    dni: null,
    cedula: null,
    birthdate: '',
    curp: '',
    rfc: '',
    nss: ''
  }

  const formik = useFormik<TeacherPayload>({
    initialValues,
    validationSchema,
    onSubmit: async (values: TeacherPayload) => {
      const formData = new FormData()
      formData.append('dni', values.dni as any)
      formData.append('cedula', values.cedula as any)

      const newPayload = { ...values, dni: undefined, cedula: undefined }

      void dispatch(createTeacher({ ...newPayload, formData }))
    }
  })

  useEffect(() => {
    void dispatch(getOnlyGrp())
  }, [])
  useEffect(() => {
    console.log(formik.errors)
  }, [formik.errors])

  return { formik, initialValues, validationSchema }
}

export default useRegisterTeacher

// const crearProfesor = async (payload: TeacherPayload): Promise<any> => {
//   try {
//     const formData = new FormData()
//     formData.append('name', payload.name)
//     formData.append('lastName', payload.lastName)
//     formData.append('email', payload.email)
//     formData.append('contrasena', payload.contrasena)
//     formData.append('telefono', payload.telefono)
//     formData.append('domicilio', payload.domicilio)
//     formData.append('assignedGroupId', payload.assignedGroupId)
//     formData.append('birthdate', payload.birthdate)
//     formData.append('curp', payload.curp)
//     formData.append('rfc', payload.rfc)
//     formData.append('nss', payload.nss)

//     if (payload.identificacion) {
//       formData.append('identificacion', payload.identificacion)
//     }
//     if (payload.cedula) {
//       formData.append('cedula', payload.cedula)
//     }

//     // Hacer la petición con Axios
//     const response = await axios.post(
//       'https://miramar-school-backend-develop.up.railway.app/api/v1/',
//       formData,
//       {
//         headers: {
//           'Content-Type': 'multipart/form-data'
//         }
//       }
//     )

//     return response.data
//   } catch (error) {
//     console.error('Error al crear el profesor:', error)
//     throw error
//   }
// }
