import { type FormikValues } from 'formik'
import { Grid, Typography } from '@mui/material'
import { InputC } from '../../../components'
import { FormField } from '../styles/styles'

const HealthDetailsForm = ({ formik }: FormikValues): JSX.Element | any => {
  const students = formik.values.students || []

  return (
    Array.from({ length: Number(formik.values.numberStudents) || 0 }).map((_, index) => (
      <>
        <Typography variant="h5" component="h4" mb={5} key={index}>
        { formik.values.numberStudents > 1
          ? `Datos de salud del estudiante ${index + 1}`
          : 'Datos de salud del estudiante'
        }
        </Typography>
        <FormField>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <InputC
                type="text"
                name={`students[${index}].healthDetailsDto.bloodType`}
                fullWidth
                placeholder="Ingresa tu tipo de sangre aquí"
                label="Tipo de sangre"
                variant="outlined"
                value={students[index]?.healthDetailsDto?.bloodType || ''} // Validamos que students[index] exista
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.touched.students?.[index]?.healthDetailsDto?.bloodType && formik.errors.students?.[index]?.healthDetailsDto?.bloodType}
              />
              <InputC
                type="text"
                name={`students[${index}].healthDetailsDto.size`}
                fullWidth
                placeholder="Ingresa tu estatura aquí"
                label="Estatura en cm"
                variant="outlined"
                value={students[index]?.healthDetailsDto?.size || ''} // Validamos que students[index] exista
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.touched.students?.[index]?.healthDetailsDto?.size && formik.errors.students?.[index]?.healthDetailsDto?.size}
              />
              <InputC
                type="text"
                name={`students[${index}].healthDetailsDto.weight`}
                fullWidth
                placeholder="Ingresa tu peso aquí"
                label="Peso en kg"
                variant="outlined"
                value={students[index]?.healthDetailsDto?.weight || ''} // Validamos que students[index] exista
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.touched.students?.[index]?.healthDetailsDto?.weight && formik.errors.students?.[index]?.healthDetailsDto?.weight}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputC
                type="text"
                name={`students[${index}].healthDetailsDto.alergies`}
                fullWidth
                placeholder="Ingresa tus alergias aquí: alergia1, alergia2, ..."
                label="Alergias"
                variant="outlined"
                value={students[index]?.healthDetailsDto?.alergies?.join(', ') || ''} // Validamos que students[index] exista
                onChange={(e) => formik.setFieldValue(`students[${index}].healthDetailsDto.alergies`, e.target.value.split(', '))}
                onBlur={formik.handleBlur}
                helperText={formik.touched.students?.[index]?.healthDetailsDto?.alergies && formik.errors.students?.[index]?.healthDetailsDto?.alergies}
              />
              <InputC
                name={`students[${index}].healthDetailsDto.wearsGlasses`}
                placeholder="Ingresa si usas lentes aquí"
                type="checkbox"
                label="Usa lentes"
                variant="outlined"
                value={students[index]?.healthDetailsDto?.wearsGlasses || false}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.touched.students?.[index]?.healthDetailsDto?.wearsGlasses && formik.errors.students?.[index]?.healthDetailsDto?.wearsGlasses}
              />
              <InputC
                type="checkbox"
                name={`students[${index}].healthDetailsDto.wearsHearingAid`}
                placeholder="Ingresa si usas aparato auditivo aquí"
                label="Usa aparato auditivo"
                variant="outlined"
                value={students[index]?.healthDetailsDto?.wearsHearingAid || false}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.touched.students?.[index]?.healthDetailsDto?.wearsHearingAid && formik.errors.students?.[index]?.healthDetailsDto?.wearsHearingAid}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputC
                type="checkbox"
                name={`students[${index}].healthDetailsDto.hasPhysicalLimitation`}
                fullWidth
                placeholder="Ingresa si tienes alguna limitación física aquí"
                label="Limitación física"
                variant="outlined"
                value={students[index]?.healthDetailsDto?.hasPhysicalLimitation || false} // Validamos que students[index] exista
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.touched.students?.[index]?.healthDetailsDto?.hasPhysicalLimitation && formik.errors.students?.[index]?.healthDetailsDto?.hasPhysicalLimitation}
              />
              <InputC
                type="checkbox"
                name={`students[${index}].healthDetailsDto.wearsOrthopedicShoes`}
                fullWidth
                placeholder="Ingresa si usas zapatos ortopédicos aquí"
                label="Usa zapatos ortopédicos"
                variant="outlined"
                value={students[index]?.healthDetailsDto?.wearsOrthopedicShoes || false} // Validamos que students[index] exista
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.touched.students?.[index]?.healthDetailsDto?.wearsOrthopedicShoes && formik.errors.students?.[index]?.healthDetailsDto?.wearsOrthopedicShoes}
              />
              <InputC
                type="checkbox"
                name={`students[${index}].healthDetailsDto.hasMentalLimitation`}
                fullWidth
                placeholder="Ingresa si tienes alguna limitación mental aquí"
                label="Discapacidad intelectual o de aprendizaje"
                variant="outlined"
                value={students[index]?.healthDetailsDto?.hasMentalLimitation || false} // Validamos que students[index] exista
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.touched.students?.[index]?.healthDetailsDto?.hasMentalLimitation && formik.errors.students?.[index]?.healthDetailsDto?.hasMentalLimitation}
              />
            </Grid>
            <Grid item xs={12}>
              <InputC
                type="textarea"
                name={`students[${index}].healthDetailsDto.description`}
                fullWidth
                placeholder="Describe"
                label="Descripción"
                variant="outlined"
                value={students[index]?.healthDetailsDto?.description || ''} // Validamos que students[index] exista
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                helperText={formik.touched.students?.[index]?.healthDetailsDto?.description && formik.errors.students?.[index]?.healthDetailsDto?.description}
              />
            </Grid>
          </Grid>
        </FormField>
      </>
    ))
  )
}

export default HealthDetailsForm
