import { FormHelperText, FormLabel } from '@mui/joy'
import { InputUi, FormControlUi } from './styles/style'
import { type PropsInput } from '@/interface'

const InputC = ({ fullWidth, placeholder, variant, type, label, helperText, startDecorator, value, name, onChange, colorLabel = '#141415', error, ...rest }: PropsInput): JSX.Element => {
  return (
    <FormControlUi sx={{ margin: '25px 0' }}>
      <FormLabel sx={{ color: colorLabel, fontWeight: '500', fontSize: '20px', lineHeight: '30px' }}>{label}</FormLabel>
      <InputUi onChange={onChange} value={value} name={name} fullWidth={fullWidth} placeholder={placeholder} variant={variant} type={type} startDecorator={startDecorator} {...rest} />
      <FormHelperText sx={{ color: 'red', fontWeight: '500', fontSize: '14px', lineHeight: '21px' }}>{helperText}</FormHelperText>
      {error && <FormHelperText sx={{ color: 'red', fontWeight: '500', fontSize: '14px', lineHeight: '21px' }}>{helperText}</FormHelperText>}
    </FormControlUi>
  )
}

export default InputC
