import { useEffect, useState } from 'react'

interface Student {
  name: string
  lastName: string
  curp: string
  group_id: string | null
}

interface User {
  _id: string
  group_id: string | null
  students: Student[]
  name: string
  lastName: string
  curp: string
  // Otros campos relevantes de User
}

export const useSeparateStudentsByGroup = (users: User[] | null): User[] => {
  const [formattedStudents, setFormattedStudents] = useState<User[]>([])

  useEffect(() => {
    if (!users) return

    const separatedStudents: User[] = []

    users.forEach((user: User) => {
      if (user.group_id === null && user.students && user.students.length > 0) {
        // Si no tiene `group_id` pero tiene estudiantes, separarlos en filas individuales
        user.students.forEach((student: Student) => {
          separatedStudents.push({
            ...user,
            name: student.name,
            lastName: student.lastName,
            curp: student.curp,
            group_id: student.group_id,
            students: [] // Limpiar el array de estudiantes para evitar mostrar duplicados
          })
        })
      } else {
        // Si tiene `group_id`, agregar el usuario directamente
        separatedStudents.push(user)
      }
    })

    setFormattedStudents(separatedStudents)

  // Usamos JSON.stringify(users) como dependencia para detectar cambios reales
  }, [users && JSON.stringify(users)])

  return formattedStudents
}
