import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getStorage, ref } from 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyDw6s5v-zplw5LM3GjDVPa4cEM14m8RYQA',
  authDomain: 'miramar-app-ba673.firebaseapp.com',
  projectId: 'miramar-app-ba673',
  storageBucket: 'miramar-app-ba673.appspot.com',
  messagingSenderId: '389720069963',
  appId: '1:389720069963:web:35bafdbf58d4601af27ace',
  measurementId: 'G-WM1EVX5EVH'
}

const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
const storage = getStorage(app)
const storageRef = ref(storage, 'mira-mar/')

export { app, analytics, storage, storageRef }
